/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import 'bootstrap/dist/css/bootstrap.min.css';

exports.onRouteUpdate = () => {
    if (typeof document !== `undefined`) {
        const gatsbyFrame = document.getElementById("___gatsby").firstChild;
        if(gatsbyFrame !== null){
          gatsbyFrame.setAttribute('aria-label','site wrapper');
        }
    }
};